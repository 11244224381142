import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import TimezoneSelect from "react-timezone-select";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DatePicker from "react-datepicker";

const MediaProvidersDetails = (props) => {
    const [Name, setName] = useState('');
    const [Timezone, setTimezone] =useState('')
    const [ClientRefCode, setClientRefCode] = useState('');
    const [RegisteredName, setRegisteredName] = useState('');
    const [TradingName, setTradingName] = useState('');
    const [PostalAddress, setPostalAddress] = useState('');
    const [State, setState] = useState('');
    const [PostCode, setPostCode] = useState('');
    const [ABN, setABN] = useState('');
    const [ACN, setACN] = useState('');
    const [WebAddress, setWebAddress] = useState('');
    const [KeyContactTitle, setKeyContactTitle] = useState('');
    const [KeyContactEmail, setKeyContactEmail] = useState('');
    const [KeyContactPhone, setKeyContactPhone] = useState('');
    const [OtherContactTitle, setOtherContactTitle] = useState('');
    const [OtherContactEmail, setOtherContactEmail] = useState('');
    const [OtherContactPhone, setOtherContactPhone] = useState('');
    const [KeyContactFinanceTitle, setKeyContactFinanceTitle] = useState('');
    const [KeyContactFinanceEmail, setKeyContactFinanceEmail] = useState('');
    const [KeyContactFinancePhone, setKeyContactFinancePhone] = useState('');
    const [OtherContactFinanceTitle, setOtherContactFinanceTitle] = useState('');
    const [OtherContactFinanceEmail, setOtherContactFinanceEmail] = useState('');
    const [OtherContactFinancePhone, setOtherContactFinancePhone] = useState('');
    const [VolumeDiscountEnabled, setVolumeDiscountEnabled] = useState(false);
    const [AgencyCommissionEnabled, setAgencyCommissionEnabled] = useState(false);
    const [VolumeDiscountPercent, setVolumeDiscountPercent] = useState('');
    const [AgencyCommissionPercent, setAgencyCommissionPercent] = useState('');
    const [SharedBillingPercent, setSharedBillingPercent] = useState('');
    const [ContractRef, setContractRef] = useState('');
    const [ContractPeriod, setContractPeriod] = useState('');
    const [AccountEmail, setAccountEmail] = useState('');
    const [AccountPassword, setAccountPassword] = useState('');
    const [AccountConfirmPassword, setAccountConfirmPassword] = useState('');
    const [StartDate, setStartDate] = useState(new Date());
    const [SharedBilling, setSharedBilling] = React.useState('');
    const [MediaProviderType, setMediaProviderType] = React.useState('');

    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();
    const [PreviewImage, setPreviewImage] = useState();
    const [PreviewThumb, setPreviewThumb] = useState();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "mediaprovider/" + id ,
                    { headers: {
                        "Authorization" : `Bearer ${account.token}`
                    }
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setTimezone(result.timezone);
                            setMediaProviderType(result.type);
                            setClientRefCode(result.company_client_ref_code);
                            setRegisteredName(result.company_registered_name);
                            setTradingName(result.company_trading_name);
                            setPostalAddress(result.company_address);
                            setState(result.company_state);
                            setPostCode(result.company_postcode);
                            setABN(result.company_abn);
                            setACN(result.company_acn);
                            setWebAddress(result.company_web);
                            setKeyContactTitle(result.executive_key_title);
                            setKeyContactEmail(result.executive_key_email);
                            setKeyContactPhone(result.executive_key_phone);
                            setOtherContactTitle(result.executive_other_title);
                            setOtherContactEmail(result.executive_other_email);
                            setOtherContactPhone(result.executive_other_phone);
                            setKeyContactFinanceTitle(result.finance_key_title);
                            setKeyContactFinanceEmail(result.finance_key_email);
                            setKeyContactFinancePhone(result.finance_key_phone);
                            setOtherContactFinanceTitle(result.finance_other_title);
                            setOtherContactFinanceEmail(result.finance_other_email);
                            setOtherContactFinancePhone(result.finance_other_phone);
                            setVolumeDiscountPercent(result.finance_volume_discount);
                            setAgencyCommissionPercent(result.finance_agency_commission);
                            setContractRef(result.contract_ref);
                            setContractPeriod(result.contract_start_date);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }else{
            return history.push('/admin/mediaproviders');
        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined)
            selector.textContent = "Media Provider details";
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <fieldset>
                                <legend>Basic Information</legend>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mb-25">
                                        <label>Name: </label>
                                        <p className="card-text">{Name}</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-25">
                                        <label>Time Zone: </label>
                                        <p className="card-text">{Timezone}</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-25">
                                        <label>Media Provider Type: </label>
                                        <p className="card-text">{MediaProviderType}</p>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-sm-12">
                            <fieldset>
                                <legend>Company</legend>
                                <div className="row">
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>Client Ref Code: </label>
                                            <p className="card-text">{ClientRefCode}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>Registered Name: </label>
                                            <p className="card-text">{RegisteredName}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>Trading Name: </label>
                                            <p className="card-text">{TradingName}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>Postal Address: </label>
                                            <p className="card-text">{PostalAddress}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>State: </label>
                                            <p className="card-text">{State}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>Postcode: </label>
                                            <p className="card-text">{PostCode}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>ABN: </label>
                                            <p className="card-text">{ABN}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>ACN: </label>
                                            <p className="card-text">{ACN}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-6 mb-25">
                                            <label>Web address: </label>
                                            <p className="card-text">{WebAddress}</p>
                                        </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-sm-12">
                            <fieldset>
                                <legend>Executives</legend>
                                <div className="row">
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <fieldset>
                                            <legend>Key Contact</legend>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Title: </label>
                                                <p className="card-text">{KeyContactTitle}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Email: </label>
                                                <p className="card-text">{KeyContactEmail}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Phone: </label>
                                                <p className="card-text">{KeyContactPhone}</p>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <fieldset>
                                            <legend>Other Contact</legend>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Title: </label>
                                                <p className="card-text">{OtherContactTitle}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Email: </label>
                                                <p className="card-text">{OtherContactEmail}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Phone: </label>
                                                <p className="card-text">{OtherContactPhone}</p>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-sm-12">
                            <fieldset>
                                <legend>Finance</legend>
                                <div className="row">
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <fieldset>
                                            <legend>Key Contact</legend>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Title: </label>
                                                <p className="card-text">{KeyContactFinanceTitle}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Email: </label>
                                                <p className="card-text">{KeyContactFinanceEmail}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Phone: </label>
                                                <p className="card-text">{KeyContactFinancePhone}</p>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <fieldset>
                                            <legend>Other Contact</legend>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Title: </label>
                                                <p className="card-text">{OtherContactFinanceTitle}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Email: </label>
                                                <p className="card-text">{OtherContactFinanceEmail}</p>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-25">
                                                <label>Phone: </label>
                                                <p className="card-text">{OtherContactFinancePhone}</p>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                                    {/*    <Form.Group className="form-group">*/}
                                    {/*        <Form.Label>Volume Discount</Form.Label>*/}
                                    {/*        <div className="container-check-input-same-row">*/}
                                    {/*            <Form.Check name="Enabled" type="checkbox" checked={VolumeDiscountEnabled} onChange={(e) => setVolumeDiscountEnabled(!VolumeDiscountEnabled)} />*/}
                                    {/*            <div>*/}
                                    {/*                <Form.Control name="name" type="text" required placeholder='%' value={VolumeDiscountPercent} onChange={(e) => setVolumeDiscountPercent(e.target.value)} />*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</div>*/}
                                    {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                                    {/*    <Form.Group className="form-group">*/}
                                    {/*        <Form.Label>Agency Commission</Form.Label>*/}
                                    {/*        <div className="container-check-input-same-row">*/}
                                    {/*            <Form.Check name="Enabled" type="checkbox" checked={AgencyCommissionEnabled} onChange={(e) => setAgencyCommissionEnabled(!AgencyCommissionEnabled)} />*/}
                                    {/*            <div>*/}
                                    {/*                <Form.Control name="name" type="text" required placeholder='%' value={AgencyCommissionPercent} onChange={(e) => setAgencyCommissionPercent(e.target.value)} />*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </Form.Group>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-sm-12">*/}
                                    {/*    <fieldset>*/}
                                    {/*        <legend>Shared Billing</legend>*/}
                                    {/*        <div className="row">*/}
                                    {/*            <div className="mb-3 col-md-6 col-sm-12">*/}
                                    {/*                <FormControl>*/}
                                    {/*                    <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>*/}
                                    {/*                    <RadioGroup*/}
                                    {/*                        aria-labelledby="demo-controlled-radio-buttons-group"*/}
                                    {/*                        name="controlled-radio-buttons-group"*/}
                                    {/*                        value={SharedBilling}*/}
                                    {/*                        onChange={handleChange}*/}
                                    {/*                    >*/}
                                    {/*                        <FormControlLabel value="vudoo" control={<Radio />} label="with Vudoo" />*/}
                                    {/*                        <FormControlLabel value="contensquare" control={<Radio />} label="with Contensquare" />*/}
                                    {/*                    </RadioGroup>*/}
                                    {/*                </FormControl>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="mb-3 col-md-6 col-sm-12">*/}
                                    {/*                <Form.Group className="form-group">*/}
                                    {/*                    /!*<Form.Label>%</Form.Label>*!/*/}
                                    {/*                    <Form.Control name="name" type="text" required placeholder='%' value={SharedBillingPercent} onChange={(e) => setSharedBillingPercent(e.target.value)} />*/}
                                    {/*                </Form.Group>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </fieldset>*/}
                                    {/*</div>*/}
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-sm-12">
                            <fieldset>
                                <legend>Contract</legend>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mb-25">
                                        <label>Ref: </label>
                                        <p className="card-text">{ContractRef}</p>
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-25">
                                        <label>Start Date: </label>
                                        <p className="card-text">{}</p>
                                        {/*<p className="card-text">{StartDate}</p>*/}
                                    </div>
                                    <div className="col-sm-12 col-md-6 mb-25">
                                        <label>Period: </label>
                                        <p className="card-text">{ContractPeriod}</p>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                    </div>
                </div>
                <div className="card-footer">
                    <NavLink className="btn btn-secondary" to="/admin/mediaproviders"><i className="fa fa-chevron-left"></i> Back</NavLink>
                </div>
            </div>
        </>
    );
};

export default MediaProvidersDetails;
