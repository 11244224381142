import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';

const SdksDetails = (props) => {
    const [Name, setName] = useState('');
    const [Clients, setClients] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "sdk/" + id ,
                    { headers: {
                        "Authorization" : `Bearer ${account.token}`
                    }
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setEnabled(result.is_active);

                            var clients = result.organizations.map(item => item.name).join(', ');
                            setClients(clients);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }else{
            return history.push('/admin/sdk');
        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined)
            selector.textContent = "SDK details";
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Name: </label>
                            <p className="card-text">{Name}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Clients: </label>
                            <p className="card-text">{Clients}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Is Active: </label>
                            <p className="card-text">{Enabled ? "True" : "False"}</p>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <NavLink className="btn btn-secondary" to="/admin/sdks"><i className="fa fa-chevron-left"></i> Back</NavLink>
                </div>
            </div>
        </>
    );
};

export default SdksDetails;
