import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import RRuleGenerator from "react-rrule-generator";
// import 'bootstrap/dist/css/bootstrap.css';       // this lib uses boostrap (v. 4.0.0-beta.2)
import 'react-rrule-generator/build/styles.css';
import DatePicker from "react-datepicker"; // react-rrule-generator's custom CSS

const NotificationForm = (props) => {
    const [Title, setTitle] = useState('');
    const [Body, setBody] = useState('');
    const [When, setWhen] = useState('');
    const [LocalTime, setLocalTime] = useState(true);
    const [RRule, setRRule] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const [Recurrent, setRecurrent] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "notification/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setTitle(result.title);
                            setBody(result.body);
                            setWhen(new Date(result.when));
                            setLocalTime(result.is_local_time);

                            // setRRule(result.rrule);
                            const rrule = {
                                rrule: result.rrule
                            };

                            setRRule(rrule);
                            setRecurrent(result.rrule !== '');

                            setEnabled(result.is_active);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        toast.error('An error occurred !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    });
            } catch (err) {
                toast.error('An error occurred !', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingData(false);
            }
        }
    }

    const changeRecurrent = async (e) => {
        if(Recurrent)
            setRRule('');
        setRecurrent(!Recurrent);
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            let data_when = new Date(When);
            if (LocalTime) {
                // For local time just get the values as if they were already in UTC, to avoid timezone shift
                data_when = new Date(Date.UTC(
                    data_when.getFullYear(),
                    data_when.getMonth(),
                    data_when.getDate(),
                    data_when.getHours(),
                    data_when.getMinutes()
                ));
            }
            if (id > 0) {
                axios.put(configData.API_SERVER + 'notification/' + id ,
                    {
                        title: Title,
                        body: Body,
                        when: data_when,
                        is_local_time: LocalTime,
                        rrule: RRule.rrule !== '' ? RRule.rrule : undefined,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/manage/notifications');
                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'notification' ,
                    {
                        title: Title,
                        body: Body,
                        when: data_when,
                        is_local_time: LocalTime,
                        rrule: RRule.rrule !== '' ? RRule.rrule : undefined,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/manage/notifications');
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {

        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit Notification";
            else
                selector.textContent = "Create Notification";
        }
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control name="title" type="text" required placeholder='Title' value={Title} onChange={(e) => setTitle(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>When</Form.Label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend calendar-icon">
                                            <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                        </div>
                                        <div className="datapickerContainer">
                                            <DatePicker
                                                selected={When}
                                                onChange={(date) => setWhen(date)}
                                                showTimeSelect
                                                timeIntervals={1}
                                                className="form-control"
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                timeFormat="HH:mm"
                                                minDate={new Date()}
                                                showYearDropdown
                                                dateFormatCalendar="MMMM"
                                                yearDropdownItemNumber={10}
                                                scrollableYearDropdown
                                                // showMonthDropdown
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Time is local</Form.Label>
                                    <Form.Check name="LocalTime" type="checkbox" checked={LocalTime} onChange={(e) => setLocalTime(!LocalTime)} />
                                </Form.Group>
                            </div>
                            {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                            {/*    <Form.Group className="form-group">*/}
                            {/*        <Form.Label>Enabled</Form.Label>*/}
                            {/*        <Form.Check name="is_active" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />*/}
                            {/*    </Form.Group>*/}
                            {/*</div>*/}
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Recurrent</Form.Label>
                                    {/*<Form.Check type="checkbox" checked={Recurrent} onChange={(e) => setRecurrent(!Recurrent)} />*/}
                                    <Form.Check type="checkbox" checked={Recurrent} onChange={changeRecurrent} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Body</Form.Label>
                                    <Form.Control
                                        cols="5"
                                        rows="4"
                                        name="body"
                                        required
                                        placeholder='Body'
                                        value={Body}
                                        onChange={(e) => setBody(e.target.value)}
                                        as="textarea"
                                    ></Form.Control>
                                </Form.Group>
                            </div>
                            {
                                (Recurrent) ?
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <Form.Group className="form-group rrule-container">
                                            <Form.Label></Form.Label>
                                            <RRuleGenerator
                                                onChange={rrule => setRRule({ rrule })}
                                                value={RRule.rrule}
                                                config={{
                                                    // hideStart: true,
                                                    repeat: ["Weekly", "Daily"],
                                                    weekly: "on",
                                                    daily: "on",
                                                    // start: ["datetime"],
                                                    end: ["Never", "After", "On date"],
                                                    weekStartsOnSunday: false,
                                                    hideError: true
                                                }}
                                                // translations={translations.english}
                                            />
                                        </Form.Group>
                                    </div>
                                    : ""

                            }
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/manage/notifications"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default NotificationForm;
