let BACKEND_SERVER = null;
let BACKEND_SERVER_URL = null;
let KEY_GOOGLE_MAPS = null;
let BASE_PATH_BACKEND = null;
let ENVIRONMENT_NAME = null;

if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
    BACKEND_SERVER = "https://api-dev.resonic.natasquad.com/api/admin/";
}

if (process.env.REACT_APP_BASE_PATH_BACKEND) {
    BASE_PATH_BACKEND = process.env.REACT_APP_BASE_PATH_BACKEND;
} else {
    BASE_PATH_BACKEND = "https://api-dev.resonic.natasquad.com/";
}

if (process.env.REACT_APP_ENVIRONMENT_NAME) {
    ENVIRONMENT_NAME = process.env.REACT_APP_ENVIRONMENT_NAME;
} else {
    ENVIRONMENT_NAME = "";
}

const config = {
    basename: '',
    defaultPath: 'manage/dashboard',
    fontFamily: `'OpenSans', sans-serif`,
    borderRadius: 12,
    API_SERVER: BACKEND_SERVER,
    URL_SERVER: BACKEND_SERVER_URL,
    KEY_GOOGLE_MAPS: KEY_GOOGLE_MAPS,
    BASE_PATH_BACKEND: BASE_PATH_BACKEND,
    ENVIRONMENT_NAME: ENVIRONMENT_NAME,
};

export default config;
