import React, { useEffect, useState } from 'react';
import DataGridList from "../../components/CommonList/DataGridList.js";
import ScheduleCalendarForm from "./calendar";
import axios from "axios";
import configData from "../../config";

const Index = () => {
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));

    const [Tokens, setTokens] = useState([]);
    const [Contents, setContents] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const columns = [
        { name: 'Token', index: 'token_name', type: 'text' },
        { name: 'Content', index: 'content_name', type: 'text' },
        { name: 'Start Date', index: 'start_date', type: 'date' },
        { name: 'End Date', index: 'end_date', type: 'date' },
        { name: 'Enabled', index: 'is_active', type: 'input-switch' }
    ];

    const options = {
        crud: true,
        add: {
            route: "/manage/schedule/form"
        },
        edit: {
            route: "/manage/schedule/form"
        },
        details: {
            route: "/manage/schedule/details"
        },
        delete: {
            route: "schedule/"
        },
        enabledOpt: {
            url_edit: 'schedule/',
            field: 'is_active'
        },
        export: {
            route: ""
        },
    }

    const url = "schedules"

    const filters = [
        { name: "Token", index: 'token', type: 'dropdown', list: Tokens, placeholder: 'Token' },
        { name: "Content", index: 'content', type: 'dropdown', list: Contents, placeholder: 'Content' },
        { name: "Search", index: 'search', type: 'text'},
    ];

    // // Restablece los filtros en el almacenamiento local
    // window.localStorage.removeItem('filters');
    // localStorage.removeItem('currentPage');

    const getTokens = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'tokens' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setTokens(result);
                        setLoadingData(false);

                    }
                    if (Tokens.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getContents = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'contents' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setContents(result);
                        setLoadingData(false);

                    }
                    if (Contents.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        getTokens();
        getContents();
    }, []);

    return (
        account.media_provider_type == 'BROADCAST' ?
            <ScheduleCalendarForm /> :
        <DataGridList url={url} columns={columns} options={options} filters={filters}/>
    );


};

export default Index;
