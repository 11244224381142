import React, {useState} from 'react';
import "./login.css";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import logo from "../../assets/img/SONICmedia.png"
import axios from 'axios';
import configData from '../../config';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

const Login = () => {

    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const userLogin = (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            axios
                .post(configData.API_SERVER + 'login', {
                    email: Email,
                    password: Password
                })
                .then(function (response) {
                    if (response.status == 200) {
                        setLoading(false);
                        var user = response.data
                        window.localStorage.setItem('userLoggin', JSON.stringify(user));
                        window.localStorage.setItem('layout', 'Basic');
                        window.location.reload()
                    }

                })
                .catch(function (error) {
                    if(error.response.status == 400 || error.response.status == 403) {
                        setLoading(false);
                        Swal.fire({
                            title: 'Error!',
                            text: "Wrong email or password",
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });
                    }
                });
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="container">
            <div className="d-flex justify-content-center h-100">
                <div className="card">
                    <div className="card-header">
                        {/*<h3>Sign In</h3>*/}
                        <img className="logo-login" src={logo} />
                        {/*<div className="d-flex justify-content-end social_icon">*/}
                            {/*<span><i className="fab fa-facebook-square"></i></span>*/}
                            {/*<span><i className="fab fa-google-plus-square"></i></span>*/}
                            {/*<span><i className="fab fa-twitter-square"></i></span>*/}
                        {/*</div>*/}
                    </div>
                    <div className="card-body">
                        <form onSubmit={userLogin} autoComplete="off">
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-fw fa-user"></i></span>
                                </div>
                                <input type="email" className="form-control" placeholder="email" value={Email} onChange={(e) => setEmail(e.target.value)} />

                            </div>
                            <div className="input-group form-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-fw fa-key"></i></span>
                                </div>
                                <input type="password" className="form-control" placeholder="password" value={Password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            {/*<div className="row align-items-center remember">*/}
                                {/*<input type="checkbox" />Remember Me*/}
                            {/*</div>*/}
                            <div className="form-group">
                                {/*<Nav.Item>*/}
                                    {/*<Nav.Link*/}
                                        {/*className="m-0 p-0"*/}
                                        {/*href="/"*/}
                                        {/*onClick={userLogin}              >*/}
                                        {/*<span className="btn login_btn">Login</span>*/}
                                    {/*</Nav.Link>*/}
                                {/*</Nav.Item>*/}
                                <input type="submit" value="Login" className="btn login_btn" />
                                {(loading) ? <div className="spinner-container-login"><Spinner className="spinner yellow-primary-color" animation="border" /></div> : ""}
                            </div>
                        </form>
                    </div>
                    {/*<div className="card-footer">*/}
                        {/*<div className="d-flex justify-content-center links">*/}
                            {/*Don't have an account?<a href="#">Sign Up</a>*/}
                        {/*</div>*/}
                        {/*<div className="d-flex justify-content-center">*/}
                            {/*<a href="#">Forgot your password?</a>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default Login;
