import React, { useEffect } from 'react';
import moment from 'moment';
import $ from 'jquery';
import 'daterangepicker';

const DateRangePicker = ({ start = moment().subtract(6, 'days'), end = moment() }) => {
    useEffect(() => {
        function cb(start, end) {
            document.getElementById('reportrange').innerHTML = start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD');
        }

        $('#reportrange').daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }, cb);

        cb(start, end);
    }, []);

    return (
        <div id="reportrange" style={{ background: '#fff', cursor: 'pointer', padding: '8px 12px', border: '1px solid #ccc', width: '100%', marginBottom: '10px', borderRadius: '4px', height: '40px' }}>
            <i className="fa fa-calendar"></i>&nbsp;
            <span></span> <i className="fa fa-caret-down"></i>
        </div>
    );
}

export default DateRangePicker;