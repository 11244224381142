import React, { useEffect, useState, SyntheticEvent } from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import configData from '../../../config';
import axios from 'axios';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DatePicker from "react-datepicker";
import TimezoneSelect from "react-timezone-select";
const BrandsForm = (props) => {

    const [Name, setName] = useState('');
    const [Timezone, setTimezone] =useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const [ClientRefCode, setClientRefCode] = useState('');
    const [RegisteredName, setRegisteredName] = useState('');
    const [TradingName, setTradingName] = useState('');
    const [PostalAddress, setPostalAddress] = useState('');
    const [State, setState] = useState('');
    const [PostCode, setPostCode] = useState('');
    const [ABN, setABN] = useState('');
    const [ACN, setACN] = useState('');
    const [WebAddress, setWebAddress] = useState('');
    const [KeyContactTitle, setKeyContactTitle] = useState('');
    const [KeyContactEmail, setKeyContactEmail] = useState('');
    const [KeyContactPhone, setKeyContactPhone] = useState('');
    const [OtherContactTitle, setOtherContactTitle] = useState('');
    const [OtherContactEmail, setOtherContactEmail] = useState('');
    const [OtherContactPhone, setOtherContactPhone] = useState('');
    const [KeyContactFinanceTitle, setKeyContactFinanceTitle] = useState('');
    const [KeyContactFinanceEmail, setKeyContactFinanceEmail] = useState('');
    const [KeyContactFinancePhone, setKeyContactFinancePhone] = useState('');
    const [OtherContactFinanceTitle, setOtherContactFinanceTitle] = useState('');
    const [OtherContactFinanceEmail, setOtherContactFinanceEmail] = useState('');
    const [OtherContactFinancePhone, setOtherContactFinancePhone] = useState('');
    const [VolumeDiscountEnabled, setVolumeDiscountEnabled] = useState(false);
    const [AgencyCommissionEnabled, setAgencyCommissionEnabled] = useState(false);
    const [VolumeDiscountPercent, setVolumeDiscountPercent] = useState('');
    const [AgencyCommissionPercent, setAgencyCommissionPercent] = useState('');
    const [SharedBillingPercent, setSharedBillingPercent] = useState('');
    const [ContractRef, setContractRef] = useState('');
    const [ContractPeriod, setContractPeriod] = useState('');
    const [AccountEmail, setAccountEmail] = useState('');
    const [AccountPassword, setAccountPassword] = useState('');
    const [AccountConfirmPassword, setAccountConfirmPassword] = useState('');
    const [StartDate, setStartDate] = useState(new Date());
    const [SharedBilling, setSharedBilling] = React.useState('vudoo');
    const [MediaProviders, setMediaProviders] = useState([]);
    const [MediaProvider, setMediaProvider] = useState(0);

    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();
    const aRef = useRef(null);

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "brand/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setMediaProvider(result.provider);
                            setTimezone(result.timezone);
                            setClientRefCode(result.company_client_ref_code);
                            setRegisteredName(result.company_registered_name);
                            setTradingName(result.company_trading_name);
                            setPostalAddress(result.company_address);
                            setState(result.company_state);
                            setPostCode(result.company_postcode);
                            setABN(result.company_abn);
                            setACN(result.company_acn);
                            setWebAddress(result.company_web);
                            setKeyContactTitle(result.executive_key_title);
                            setKeyContactEmail(result.executive_key_email);
                            setKeyContactPhone(result.executive_key_phone);
                            setOtherContactTitle(result.executive_other_title);
                            setOtherContactEmail(result.executive_other_email);
                            setOtherContactPhone(result.executive_other_phone);
                            setKeyContactFinanceTitle(result.finance_key_title);
                            setKeyContactFinanceEmail(result.finance_key_email);
                            setKeyContactFinancePhone(result.finance_key_phone);
                            setOtherContactFinanceTitle(result.finance_other_title);
                            setOtherContactFinanceEmail(result.finance_other_email);
                            setOtherContactFinancePhone(result.finance_other_phone);
                            setVolumeDiscountPercent(result.finance_volume_discount);
                            setAgencyCommissionPercent(result.finance_agency_commission);
                            setContractRef(result.contract_ref);
                            setContractPeriod(result.contract_start_date);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        toast.error('An error occurred !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    });
            } catch (err) {
                toast.error('An error occurred !', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        try {
            if (id > 0) {
                axios.put(configData.API_SERVER + 'brand/' + id ,
                    {
                        provider: MediaProvider,
                        name: Name,
                        timezone: Timezone.value,
                        is_active: true,
                        company_client_ref_code: ClientRefCode,
                        company_registered_name: RegisteredName,
                        company_trading_name: TradingName,
                        company_address: PostalAddress,
                        company_state: State,
                        company_postcode: PostCode,
                        company_abn: ABN,
                        company_acn: ACN,
                        company_web: WebAddress,
                        executive_key_title: KeyContactTitle,
                        executive_key_email: KeyContactEmail,
                        executive_key_phone: KeyContactPhone,
                        executive_other_title: OtherContactTitle,
                        executive_other_email: OtherContactEmail,
                        executive_other_phone: OtherContactPhone,
                        finance_key_title: KeyContactFinanceTitle,
                        finance_key_email: KeyContactFinanceEmail,
                        finance_key_phone: KeyContactFinancePhone,
                        finance_other_title: OtherContactFinanceTitle,
                        finance_other_email: OtherContactFinanceEmail,
                        finance_other_phone: OtherContactFinancePhone,
                        finance_volume_discount: null,
                        finance_agency_commission: null,
                        finance_shared_billing_vudoo: null,
                        finance_shared_billing_contentsquare: null,
                        contract_ref: ContractRef,
                        contract_start_date: setStartDate,
                        contract_period: ContractPeriod,
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/admin/brands');
                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'brand' ,
                    {
                        provider: MediaProvider,
                        name: Name,
                        timezone: Timezone.value,
                        is_active: true,
                        company_client_ref_code: ClientRefCode,
                        company_registered_name: RegisteredName,
                        company_trading_name: TradingName,
                        company_address: PostalAddress,
                        company_state: State,
                        company_postcode: PostCode,
                        company_abn: ABN,
                        company_acn: ACN,
                        company_web: WebAddress,
                        executive_key_title: KeyContactTitle,
                        executive_key_email: KeyContactEmail,
                        executive_key_phone: KeyContactPhone,
                        executive_other_title: OtherContactTitle,
                        executive_other_email: OtherContactEmail,
                        executive_other_phone: OtherContactPhone,
                        finance_key_title: KeyContactFinanceTitle,
                        finance_key_email: KeyContactFinanceEmail,
                        finance_key_phone: KeyContactFinancePhone,
                        finance_other_title: OtherContactFinanceTitle,
                        finance_other_email: OtherContactFinanceEmail,
                        finance_other_phone: OtherContactFinancePhone,
                        finance_volume_discount: null,
                        finance_agency_commission: null,
                        finance_shared_billing_vudoo: null,
                        finance_shared_billing_contentsquare: null,
                        contract_ref: ContractRef,
                        contract_start_date: setStartDate,
                        contract_period: ContractPeriod,
                        email: AccountEmail,
                        password: AccountPassword
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/admin/brands');
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {

        }
    }

    const getMediaProviders = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'mediaproviders' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setMediaProviders(result);
                        setLoadingData(false);

                    }
                    if (MediaProviders.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const onChangeStartDate = async (date) => {
        setStartDate(date)
    }

    const handleChange = (event) => {
        setSharedBilling(event.target.value);
        // console.log(event.target.value);
    };

    useEffect(() => {
        getById();
        getMediaProviders();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit Brand";
            else
                selector.textContent = "Create Brand";
        }
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                <fieldset>
                                    <legend>Basic Information</legend>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control name="name" type="text" required placeholder='Name' value={Name} onChange={(e) => setName(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Time Zone</Form.Label>
                                                <TimezoneSelect
                                                    value={Timezone}
                                                    onChange={setTimezone}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </fieldset>

                                <div className="mb-3 col-md-6 col-sm-12">
                                    <Form.Group className="form-group">
                                        <Form.Label>Media Provider,</Form.Label>
                                        <select className="form-control" name="Token" value={MediaProvider} onChange={(e) => setMediaProvider(e.target.value)}>
                                            {MediaProviders.map((item, i) => (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </Form.Group>
                                </div>

                            </div>
                            {
                                (id > 0) ? '' :
                                    <div className="col-sm-12">
                                        <fieldset>
                                            <legend>Administration Account</legend>
                                            <div className="row">
                                                <div className="mb-3 col-md-6 col-sm-12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control name="name" type="email" required placeholder='Email' value={AccountEmail} onChange={(e) => setAccountEmail(e.target.value)} />
                                                    </Form.Group>
                                                </div>
                                                <div className="mb-3 col-md-6 col-sm-12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control name="name" type="password" required placeholder='Password' value={AccountPassword} onChange={(e) => setAccountPassword(e.target.value)} />
                                                    </Form.Group>
                                                </div>
                                                <div className="mb-3 col-md-6 col-sm-12">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <Form.Control name="name" type="password" required placeholder='Confirm Password' value={AccountConfirmPassword} onChange={(e) => setAccountConfirmPassword(e.target.value)} />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>
                            }
                            <div className="col-sm-12">
                                <fieldset>
                                    <legend>Company</legend>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Client ref code</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Client ref code' value={ClientRefCode} onChange={(e) => setClientRefCode(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Registered Name</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Registered Name' value={RegisteredName} onChange={(e) => setRegisteredName(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Trading Name</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Trading Name' value={TradingName} onChange={(e) => setTradingName(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Postal Address</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Postal Address' value={PostalAddress} onChange={(e) => setPostalAddress(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='State' value={State} onChange={(e) => setState(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Postcode</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Postcode' value={PostCode} onChange={(e) => setPostCode(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>ABN</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='ABN' value={ABN} onChange={(e) => setABN(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>ACN</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='ACN' value={ACN} onChange={(e) => setACN(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Web address</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Web address' value={WebAddress} onChange={(e) => setWebAddress(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-sm-12">
                                <fieldset>
                                    <legend>Executives</legend>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <fieldset>
                                                <legend>Key Contact</legend>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Title' value={KeyContactTitle} onChange={(e) => setKeyContactTitle(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Email' value={KeyContactEmail} onChange={(e) => setKeyContactEmail(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Phone' value={KeyContactPhone} onChange={(e) => setKeyContactPhone(e.target.value)} />
                                                </Form.Group>
                                            </fieldset>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <fieldset>
                                                <legend>Other Contact</legend>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Title' value={OtherContactTitle} onChange={(e) => setOtherContactTitle(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Email' value={OtherContactEmail} onChange={(e) => setOtherContactEmail(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Phone' value={OtherContactPhone} onChange={(e) => setOtherContactPhone(e.target.value)} />
                                                </Form.Group>
                                            </fieldset>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>

                            <div className="col-sm-12">
                                <fieldset>
                                    <legend>Finance</legend>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <fieldset>
                                                <legend>Key Contact</legend>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Title' value={KeyContactFinanceTitle} onChange={(e) => setKeyContactFinanceTitle(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Email' value={KeyContactFinanceEmail} onChange={(e) => setKeyContactFinanceEmail(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Phone' value={KeyContactFinancePhone} onChange={(e) => setKeyContactFinancePhone(e.target.value)} />
                                                </Form.Group>
                                            </fieldset>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <fieldset>
                                                <legend>Other Contact</legend>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Title' value={OtherContactFinanceTitle} onChange={(e) => setOtherContactFinanceTitle(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Email' value={OtherContactFinanceEmail} onChange={(e) => setOtherContactFinanceEmail(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control name="name" type="text" placeholder='Phone' value={OtherContactFinancePhone} onChange={(e) => setOtherContactFinancePhone(e.target.value)} />
                                                </Form.Group>
                                            </fieldset>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Volume Discount</Form.Label>
                                                <div className="container-check-input-same-row">
                                                    <Form.Check name="Enabled" type="checkbox" checked={VolumeDiscountEnabled} onChange={(e) => setVolumeDiscountEnabled(!VolumeDiscountEnabled)} />
                                                    <div>
                                                        <Form.Control name="name" type="text" placeholder='%' value={VolumeDiscountPercent} onChange={(e) => setVolumeDiscountPercent(e.target.value)} />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Agency Commission</Form.Label>
                                                <div className="container-check-input-same-row">
                                                    <Form.Check name="Enabled" type="checkbox" checked={AgencyCommissionEnabled} onChange={(e) => setAgencyCommissionEnabled(!AgencyCommissionEnabled)} />
                                                    <div>
                                                        <Form.Control name="name" type="text" placeholder='%' value={AgencyCommissionPercent} onChange={(e) => setAgencyCommissionPercent(e.target.value)} />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-sm-12">
                                            <fieldset>
                                                <legend>Shared Billing</legend>
                                                <div className="row">
                                                    <div className="mb-3 col-md-6 col-sm-12">
                                                        <FormControl>
                                                            <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                                                            <RadioGroup
                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                name="controlled-radio-buttons-group"
                                                                value={SharedBilling}
                                                                onChange={handleChange}
                                                            >
                                                                <FormControlLabel value="vudoo" control={<Radio />} label="with Vudoo" />
                                                                <FormControlLabel value="contensquare" control={<Radio />} label="with Contensquare" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                    <div className="mb-3 col-md-6 col-sm-12">
                                                        <Form.Group className="form-group">
                                                            {/*<Form.Label>%</Form.Label>*/}
                                                            <Form.Control name="name" type="text" placeholder='%' value={SharedBillingPercent} onChange={(e) => setSharedBillingPercent(e.target.value)} />
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>

                            <div className="col-sm-12">
                                <fieldset>
                                    <legend>Contract</legend>
                                    <div className="row">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Ref</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Ref' value={ContractRef} onChange={(e) => setContractRef(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Start Date</Form.Label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend calendar-icon">
                                                        <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                    </div>
                                                    <div className="datapickerContainer">
                                                        <DatePicker
                                                            selected={StartDate}
                                                            onChange={onChangeStartDate}
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            // minDate={new Date()}
                                                            showYearDropdown
                                                            dateFormatCalendar="MMMM"
                                                            yearDropdownItemNumber={10}
                                                            scrollableYearDropdown
                                                            // showMonthDropdown
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Period</Form.Label>
                                                <Form.Control name="name" type="text" placeholder='Period' value={ContractPeriod} onChange={(e) => setContractPeriod(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/admin/brands"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default BrandsForm;
