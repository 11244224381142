import React,{useEffect, useState, useRef} from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
// import React, {useEffect, useState} from "@types/react";
import {NavLink, useHistory} from "react-router-dom";
import axios from "axios";
import configData from "../../config";
import Swal from "sweetalert2";
import {Button, Card, Collapse, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import Icon from 'awesome-react-icons';
import { toast } from 'react-toastify';
import { format } from 'fecha';
import RRuleGenerator from "react-rrule-generator";
import 'react-rrule-generator/build/styles.css';


const ScheduleCalendarForm = (props) => {
    const [EventData, setEventdata] = useState( {
        allFavs: [],
        favObj: {},
        start: '',
        end: '',
        filledIn: false,
        practiceTimes: []
    });
    const [title, setTitle] = useState("");
    const [Tokens, setTokens] = useState([]);
    const [Token, setToken] = useState(0);
    const [Contents, setContents] = useState([]);
    const [Content, setContent] = useState(0);
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [LocalTime, setLocalTime] = useState(true);
    const [Enabled, setEnabled] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(0);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [events, setEvents] = useState([]);
    const start = new Date();
    const end = new Date(new Date().setMinutes(start.getMinutes() + 30));

    const [paramsString, setParamsString] = useState("");
    const [duration, setDuration] = useState("00:30:00");
    const [range, setRange] = useState(2);

    const [Recurrent, setRecurrent] = useState(true);
    const [RRule, setRRule] = useState('');
    const [EditEvent, setEditEvent] = useState(false);

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "schedule/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setToken(result.token);
                            setContent(result.content);



                            var start_date = new Date(result.start_date);
                            setStartDate(start_date);

                            var end_date = new Date(result.end_date);
                            setEndDate(end_date);

                            setLocalTime(result.is_local_time);

                            setEnabled(result.is_active);

                            const rrule = {
                                rrule: result.rrule
                            };

                            setRRule(rrule);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }
    }

    const saveData = async (e) => {
        if (e)
            e.preventDefault();

        // setLoadingData(true);
        try {
            let data_startdate = new Date(StartDate);
            let data_enddate = new Date(EndDate);
            if (LocalTime) {
                // For local time just get the values as if they were already in UTC, to avoid timezone shift
                data_startdate = new Date(Date.UTC(
                    data_startdate.getFullYear(),
                    data_startdate.getMonth(),
                    data_startdate.getDate(),
                    data_startdate.getHours(),
                    data_startdate.getMinutes(),
                    data_startdate.getSeconds(),
                ));

                data_enddate = new Date(Date.UTC(
                    data_enddate.getFullYear(),
                    data_enddate.getMonth(),
                    data_enddate.getDate(),
                    data_enddate.getHours(),
                    data_enddate.getMinutes(),
                    data_enddate.getSeconds(),
                ));
            }
            if (id > 0) {
                axios.put(configData.API_SERVER + 'schedule/' + id ,
                    {
                        token: Token > 0 ? Token : e.target.elements.Token.value,
                        content: Content > 0 ? Content : e.target.elements.Content.value,
                        start_date: data_startdate,
                        end_date: data_enddate,
                        is_local_time: LocalTime,
                        // rrule: RRule.rrule,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            getSchedules(false)
                            handleClose()

                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'schedule' ,
                    {
                        token: Token > 0 ? Token : e.target.elements.Token.value,
                        content: Content > 0 ? Content : e.target.elements.Content.value,
                        start_date: data_startdate,
                        end_date: data_enddate,
                        is_local_time: LocalTime,
                        rrule: RRule.rrule,
                        is_active: Enabled
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            getSchedules(false)
                            handleClose()
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {
        }
    }

    const updateEvent = async (event) => {
        let data_startdate = new Date(event.start);
        let data_enddate = new Date(event.end);
        if (event.extendedProps.is_local_time) {
            // For local time just get the values as if they were already in UTC, to avoid timezone shift
            data_startdate = new Date(Date.UTC(
                data_startdate.getFullYear(),
                data_startdate.getMonth(),
                data_startdate.getDate(),
                data_startdate.getHours(),
                data_startdate.getMinutes(),
                data_startdate.getSeconds(),
            ));

            data_enddate = new Date(Date.UTC(
                data_enddate.getFullYear(),
                data_enddate.getMonth(),
                data_enddate.getDate(),
                data_enddate.getHours(),
                data_enddate.getMinutes(),
                data_enddate.getSeconds(),
            ));
        }
        axios.put(configData.API_SERVER + 'schedule/' + event.extendedProps.id ,
            {
                token: event.extendedProps.token,
                content: event.extendedProps.content,
                start_date: data_startdate,
                end_date: data_enddate,
                is_active: event.extendedProps.is_active,
                is_local_time: event.extendedProps.is_local_time,
            },
            {
                headers: {"Authorization" : `Bearer ${account.token}`}
            })
            .then(function (response) {
                if (response.status == 200 || response.status == 204) {
                    toast.success('Successful Operation !', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    getSchedules(false)
                }
            })
            .catch(function (error) {

            });
    }

    const getTokens = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'tokens?limit=100' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setTokens(result);
                        setLoadingData(false);

                    }
                    if (Tokens.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getContents = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'contents?limit=100' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data.results;
                        setContents(result);
                        setLoadingData(false);

                    }
                    if (Contents.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    const getSchedules = (showLoading = true, params = paramsString) => {
        if(showLoading)
            setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'schedules' + params,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;
                        var data = [];
                        for(var i = 0; i < result.length; ++i) {

                            data.push(
                                {
                                    title: (result[i].content_ref != undefined && result[i].content_ref != null && result[i].content_ref != "") ? result[i].content_name + " [" + result[i].content_ref + "]" : result[i].content_name,
                                    start: result[i].start_date,
                                    end: result[i].end_date,
                                    backgroundColor: result[i].content_color,
                                    extendedProps: {
                                        id: result[i].id,
                                        token: result[i].token,
                                        token_name: result[i].token_name,
                                        content: result[i].content,
                                        content_name: result[i].content_name,
                                        is_active: result[i].is_active,
                                        start_date: result[i].start_date,
                                        end_date: result[i].end_date,
                                        is_local_time: result[i].is_local_time,
                                    }
                                }
                            )
                        }
                        if(showLoading)
                            setLoadingData(false);
                         setEvents(data)

                    }
                })
                .catch(function (error) {
                    if(showLoading)
                        setLoadingData(false);
                });
        } catch (err) {
            if(showLoading)
                setLoadingData(false);
        }
    }

    // const data = [
    //     {
    //         title: "sala 1",
    //         start,
    //         end,
    //         backgroundColor: "green",
    //         extendedProps: { id: 1 },
    //     },
    //     {
    //         title: "sala 2",
    //         start: new Date(new Date().setHours(start.getHours() + 1)),
    //         end: new Date(new Date().setHours(start.getHours() + 2)),
    //         backgroundColor: "purple",
    //         extendedProps: { id: 2 },
    //     },
    //     {
    //         title: "sala 3",
    //         start: new Date(new Date().setHours(start.getHours() + 2)),
    //         end: new Date(new Date().setHours(start.getHours() + 3)),
    //         backgroundColor: "#000",
    //         extendedProps: { id: 3 },
    //     },
    // ];

    const OnDelete = async () => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.value) {
                DeleteItem(id);
            }
        })
    }

    const DeleteItem = async (id) => {
        // setLoadingData(true);
        const requestOptions = {
            token: `${account.token}`,
            headers: { Authorization: `${account.token}` }
        };
        try {
            axios
                .delete(configData.API_SERVER + "schedule/" + id, { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    console.log(response)
                    if (response.status == 200 || response.status == 204) {
                        toast.success('Successful Operation !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        getSchedules(false);
                        handleClose();

                    } else {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        // setLoadingData(false);
                    }
                })
                .catch(function (error) {
                    //Swal.fire({
                    //    title: 'Erreur!',
                    //    text: "Une erreur s'est produite",
                    //    icon: 'error',
                    //    confirmButtonText: 'OK'
                    //});
                    // setLoadingData(false);

                });
        } catch (err) {
            //Swal.fire({
            //    title: 'Erreur!',
            //    text: "Une erreur s'est produite",
            //    icon: 'error',
            //    confirmButtonText: 'OK'
            //});
            // setLoadingData(false);
        }
    }

    const calendarRef = useRef(null);

    const changeRecurrent = async (e) => {
        if(Recurrent)
            setRRule('');
        setRecurrent(!Recurrent);
    }

    useEffect(() => {
        getSchedules();
        getTokens();
        getContents();
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                setTitle("Edit Schedule");
            else
                setTitle("Create Schedule");
        }
    }, []);

    /**Filters in Calendar*/
    const filters = [
        { name: "Token", index: 'token', type: 'dropdown', list: Tokens, placeholder: 'Token' },
        { name: "Content", index: 'content', type: 'dropdown', list: Contents, placeholder: 'Content' },
        { name: "Search", index: 'search', type: 'text'},
    ];

    const [isVisible, initHs] = useState(false)
    const invokeCollapse = () => {
        return initHs(!isVisible)
    }

    const onSearch = (event) => {
        initHs(false);
        event.preventDefault();
        setParamsString("");
        var filterString = "";

        filters.map((item) => {
            let index = item.index
            if (event.target[index] != undefined) {
                item.value = (item.type == "checkbox") ? ((event.target[index].checked) ? 1 : 0) : event.target[index].value;

                if (item.value != undefined && item.value != "" && item.value != null) {
                    let sign = filterString !="" ? "&" : "?";
                    filterString += sign + item.index + "=" + item.value;
                }
            }
        });
        setParamsString(filterString);
        getSchedules(false, filterString);
    }

    const formRef = useRef(null);

    const activateButtons = (event) => {
        setEnableButtons(false);
        for (let i = 0; i < formRef.current.length; i++) {
            if ((formRef.current[i].type == 'checkbox' && formRef.current[i].checked) || (formRef.current[i].type != 'checkbox' && formRef.current[i].value != "")) {
                setEnableButtons(true);
            }
        }
    }

    const [enableButtons, setEnableButtons] = useState(false);
    const resertForm = () => {
        initHs(false);
        formRef.current.reset();
        for (let i = 0; i < formRef.current.length; i++) {
            if (formRef.current[i].type == 'checkbox')
                formRef.current[i].checked = false;

            if (formRef.current[i].type != 'checkbox')
                formRef.current[i].value = ""
        }
        setParamsString("");
        setEnableButtons(false);
        getSchedules(true, "");
    }

    /**END Filters in Calendar*/
    const handleRangeChange = e => {
        setRange(e.currentTarget.value);
        const value = parseInt(e.currentTarget.value);
        switch (value){
            case 1:
                setDuration("01:00:00");
                // console.log("00:05:00");
                break;
            case 2:
                setDuration("00:30:00");
                // console.log("00:05:00");
                break;
            case 3:
                setDuration("00:15:00");
                // console.log("00:05:00");
                break;
            case 4:
                setDuration("00:05:00");
                // console.log("00:15:00");
                break;
            case 5:
                setDuration("00:01:00");
                // console.log("00:30:00");
                break;
            default:
                break;
        }
    };

    const slotLabelContent = (slotInfo) => {
        let time = slotInfo.date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
        time = time.replace("24:", "00:")
        return (
            <span className="fc-timegrid-slot-label-cushion">{time}</span>
        );
    };

    return (
        loadingData ?
            <div className="preload"><div className="preloader-spinner"></div></div>
            :
            // <div id='full-calendar'>
            //     <FullCalendar
            //         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            //         initialView="dayGridMonth"
            //         select={handleTimeSelection(this)}
            //         eventBackgroundColor={'#FF4500'}
            //         headerToolbar={{
            //             left: 'prev,next',
            //             center: 'title',
            //             right: 'dayGridMonth,timeGridWeek,timeGridDay'
            //         }}
            //         editable={true}
            //         selectable={true}
            //         dayMaxEvents={true}
            //         aspectRadio={6}
            //         height={600}
            //         events={this.EventData.practiceTimes}
            //     />
            // </div>




            <>
                <div className="row">
                    <div className="col-md-9 col-sm-12">
                        <Card className="filterContainer">
                            <a href="#" variant="success" onClick={invokeCollapse}>
                                <span>Filters</span> {isVisible ? <i className="nc-icon fa fa-fw fa-angle-up"></i> : <i className="nc-icon fa fa-fw fa-angle-down"></i>}
                            </a>
                            <Collapse in={isVisible}>
                                <div className="collapsePanel">
                                    <Form onSubmit={onSearch} ref={formRef}>
                                        <div className="general-filters-container">
                                            {
                                                filters.map((column, i) => {
                                                    let type = column.type
                                                    let index = column.index
                                                    let name = column.name
                                                    switch (type) {
                                                        case "checkbox":
                                                            return (
                                                                <Form.Group className="mb-3 filter-list" key={i}>
                                                                    <Form.Label>{name}</Form.Label>
                                                                    <Form.Check type="checkbox" name={index} onChange={activateButtons} />
                                                                </Form.Group>
                                                            );
                                                        case "text":
                                                            return (
                                                                <Form.Group className="mb-3 filter-list" key={i}>
                                                                    <Form.Label>{name}</Form.Label>
                                                                    <Form.Control type="text" placeholder={name} name={index} onChange={activateButtons} />
                                                                </Form.Group>
                                                            );
                                                        case "number":
                                                            return (
                                                                <Form.Group className="mb-3 filter-list" key={i}>
                                                                    <Form.Label>{name}</Form.Label>
                                                                    <div className="filter-number">
                                                                        {(column.opt != undefined && column.opt != null && column.opt != "") ?
                                                                            <div className="number-opt">
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="<"
                                                                                    defaultChecked
                                                                                    value="<"
                                                                                    name={column.opt}
                                                                                    type="radio"
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label=">"
                                                                                    value=">"
                                                                                    name={column.opt}
                                                                                    type="radio"
                                                                                />
                                                                                <Form.Check
                                                                                    inline
                                                                                    label="="
                                                                                    value="="
                                                                                    name={column.opt}
                                                                                    type="radio"
                                                                                />
                                                                            </div>
                                                                            : null}
                                                                        <Form.Control type="number" placeholder={name} name={index} onChange={activateButtons} />

                                                                    </div>
                                                                </Form.Group>
                                                            );
                                                        case "dropdown":
                                                            return (
                                                                <Form.Group className="mb-3 filter-list" key={i}>
                                                                    <Form.Label>{name}</Form.Label>
                                                                    <Form.Select name={index} onChange={activateButtons}>
                                                                        <option value="">Select option</option>
                                                                        {column.list.map(item =>
                                                                            <option className="p-10" value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            );
                                                        case "date":
                                                            return (
                                                                <Form.Group className="mb-3 filter-list" key={i}>
                                                                    <Form.Label>{name}</Form.Label>
                                                                    <div className="input-group mb-3">
                                                                        <div className="input-group-prepend calendar-icon">
                                                                            <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                                        </div>
                                                                        <div className="datapickerContainer">
                                                                            <Form.Control type="date" placeholder={name} name={index} onChange={activateButtons} />
                                                                        </div>
                                                                    </div>
                                                                </Form.Group>
                                                            );
                                                        default:
                                                            return null;
                                                    }
                                                })
                                            }
                                        </div>
                                        <br />
                                        {enableButtons ?
                                            <div className="form-footer">
                                                <button type="submit" className="btn btn-primary button-filters"><i className="fa fa-fw fa-search"></i> Search</button>
                                                <button type="button" className="btn btn-secondary button-filters" onClick={resertForm}><i className="fa fa-fw fa-refresh"></i> Reset</button>
                                            </div>
                                            :
                                            <div className="form-footer">
                                                <button type="submit" className="disabled btn btn-primary button-filters"><i className="fa fa-fw fa-search"></i> Search</button>
                                                <button type="button" className="disabled btn btn-secondary button-filters" onClick={resertForm}><i className="fa fa-fw fa-refresh"></i> Reset</button>
                                            </div>
                                        }
                                    </Form>
                                </div>
                            </Collapse>
                        </Card>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <Card className="zoom-container">
                            <Form.Label>Zoom:</Form.Label>
                            <i className="fa fa-minus"></i>
                            <input
                                onChange={handleRangeChange}
                                type="range"
                                min="1"
                                value={range}
                                max="5"
                                step="1"
                                list="tick-list"
                            />
                            <i className="fa fa-plus"></i>
                        </Card>
                    </div>
                </div>

                <FullCalendar
                    nowIndicator={true}
                    editable={true}
                    eventResizableFromStart={true}
                    eventDurationEditable={true}
                    // selectOverlap={false}
                    eventStartEditable={true}
                    snapDuration="00:00:01"
                    slotLabelContent={slotLabelContent}
                    views={{
                        dayGrid: {
                            selectable: true,
                        },
                        timeGrid: {
                            selectable: true,
                        },
                        timeGridDay: {
                            selectable: true,
                            eventMaxStack: 5,
                        },
                        dayGridMonth: {
                            selectable: true,
                        },
                    }}
                    ref={calendarRef}
                    plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                    initialView="timeGridWeek"
                    eventDrop={(info) => {
                        updateEvent(info.event);

                    }}
                    eventResize={(info) => {
                        updateEvent(info.event);
                    }}
                    eventClick={(info) =>{
                        var start_date = new Date(info.event.extendedProps.start_date);
                        setStartDate(start_date);
                        var end_date = new Date(info.event.extendedProps.end_date);
                        setEndDate(end_date);
                        setLocalTime(info.event.extendedProps.is_local_time);
                        setId(info.event.extendedProps.id);
                        setToken(info.event.extendedProps.token);
                        setContent(info.event.extendedProps.content);
                        setEnabled(info.event.extendedProps.is_active);

                        setRecurrent(false);
                        setEditEvent(true);

                        handleShow();
                    }}
                    select={(info) => {
                        // setStartDate(info.startStr)
                        // setEndDate(info.endStr)
                        var start_date = new Date(info.startStr);
                        setStartDate(start_date);
                        var end_date = new Date(info.endStr);
                        setEndDate(end_date);
                        setLocalTime(true);
                        setId(0);
                        setToken(0);
                        setContent(0);
                        setEnabled(true);

                        setRecurrent(false);
                        setEditEvent(false);

                        handleShow();
                    }}
                    events={events}
                    eventColor="#c27500"
                    displayEventEnd={true}
                    // locale={"pt-br"}
                    // timeZone={"UTF"}
                    titleFormat={{ year: "numeric", month: "long", day: "numeric" }}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                    }}
                    dayMaxEventRows={5}
                    eventMaxStack={2}
                    // allDayText={"24h"}
                    allDaySlot={false}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }}
                    aspectRadio={6}
                    slotDuration={duration}
                    // slotLabelInterval={"00:05:00"}
                    height="calc(100% - 76px)"
                />
                <Modal show={show} onHide={handleClose}>
                    <Form className="create-form" onSubmit={saveData} autoComplete="off">
                        <div className="card">
                            <div className="card-header">
                                {title}

                                {(id > 0) ?
                                    <a href="#" className="crud-icons crud-icon-delete delete-event-calendar"
                                       onClick={() => OnDelete()}>
                                        <Icon name="trash"/>
                                    </a>
                                    :
                                    ""
                                }
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Start Date</Form.Label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend calendar-icon">
                                                    <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                </div>
                                                <div className="datapickerContainer">
                                                    <DatePicker
                                                        showTimeSelect
                                                        onChange={(date) => setStartDate(date)}
                                                        timeFormat="HH:mm:ss"
                                                        timeIntervals={1}
                                                        selected={StartDate}
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy HH:mm:ss"
                                                        // readOnly={true}
                                                        minDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>End Date</Form.Label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend calendar-icon">
                                                    <span className="input-group-text" ><i className="fa fa-fw fa-calendar"></i></span>
                                                </div>
                                                <div className="datapickerContainer">
                                                    <DatePicker
                                                        showTimeSelect
                                                        onChange={(date) => setEndDate(date)}
                                                        timeFormat="HH:mm:ss"
                                                        timeIntervals={1}
                                                        selected={EndDate}
                                                        className="form-control"
                                                        dateFormat="dd/MM/yyyy HH:mm:ss"
                                                        // readOnly={true}
                                                        minDate={StartDate}
                                                    />
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Token</Form.Label>
                                            <select className="form-control" name="Token" value={Token} onChange={(e) => setToken(e.target.value)}>
                                                {Tokens.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Content</Form.Label>
                                            <select className="form-control" name="Content" value={Content} onChange={(e) => setContent(e.target.value)}>
                                                {Contents.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.name} {(item.ref != undefined && item.ref != null && item.ref != "") ? "[" + item.ref + "]" : ""}</option>
                                                ))}
                                            </select>
                                        </Form.Group>
                                    </div>
                                    <div className="mb-3 col-md-6 col-sm-12">
                                        <Form.Group className="form-group">
                                            <Form.Label>Time is local</Form.Label>
                                            <Form.Check name="local_time" type="checkbox" checked={LocalTime} onChange={(e) => setLocalTime(!LocalTime)} />
                                        </Form.Group>
                                    </div>
                                    {/*<div className="mb-3 col-md-6 col-sm-12">*/}
                                    {/*    <Form.Group className="form-group">*/}
                                    {/*        <Form.Label>Enabled</Form.Label>*/}
                                    {/*        <Form.Check name="Enabled" type="checkbox" checked={Enabled} onChange={(e) => setEnabled(!Enabled)} />*/}
                                    {/*    </Form.Group>*/}
                                    {/*</div>*/}
                                    {
                                        (!EditEvent) ?
                                            <div className="mb-3 col-md-6 col-sm-12">
                                                <Form.Group className="form-group" >
                                                    <Form.Label>Recurrent</Form.Label>
                                                    {/*<Form.Check type="checkbox" checked={Recurrent} onChange={(e) => setRecurrent(!Recurrent)} />*/}
                                                    <Form.Check type="checkbox" checked={Recurrent} onChange={changeRecurrent} />
                                                </Form.Group>
                                            </div>
                                            : ""
                                    }
                                    {
                                        (Recurrent) ?
                                            <div className="mb-3 col-12">
                                                <Form.Group className="form-group rrule-container">
                                                    <Form.Label></Form.Label>
                                                    <RRuleGenerator
                                                        onChange={rrule => setRRule({ rrule })}
                                                        value={RRule.rrule}
                                                        config={{
                                                            // hideStart: true,
                                                            repeat: ["Weekly", "Daily", "Hourly"],
                                                            weekly: "on",
                                                            daily: "on",
                                                            // start: ["datetime"],
                                                            end: ["After", "On date"],
                                                            weekStartsOnSunday: false,
                                                            hideError: true
                                                        }}
                                                        // translations={translations.english}
                                                    />
                                                </Form.Group>
                                            </div>
                                            : ""

                                    }
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-12 footer-form">
                                        <Button className="btn btn-secondary" onClick={handleClose}><i className="fa fa-fw fa-times"></i> Cancel</Button>
                                        <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </>

    );
};

export default ScheduleCalendarForm;