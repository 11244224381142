import React, { useEffect, useState, SyntheticEvent } from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {Form, Button} from 'react-bootstrap';
import configData from '../../../config';
import axios from 'axios';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const SdksForm = (props) => {

    const [Name, setName] = useState('');
    const [Clients, setClients] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [Key, setKey] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();
    const aRef = useRef(null);
    const animatedComponent = makeAnimated();
    const [selectedOptions, setSelectedOptions] = useState();

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "sdk/" + id ,
                    { headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setIsActive(result.is_active);

                            var data = [];
                            result.organizations.map((option) => (
                                data.push({
                                    value: option.id,
                                    label: option.name
                                })
                            ));
                            setSelectedOptions(data);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        toast.error('An error occurred !', {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        setLoadingData(false);
                    });
            } catch (err) {
                toast.error('An error occurred !', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingData(false);
            }
        }
    }

    const handleSelect = () => {
        // console.log(Clients)
    }

    const saveData = async (e) => {
        e.preventDefault();
        setLoadingData(true);
        var organizationsData = [];
        selectedOptions.map((option) => (
            organizationsData.push(
                option.value
            )
        ));
        try {
            if (id > 0) {
                axios.put(configData.API_SERVER + 'sdk/' + id ,
                    {
                        name: Name,
                        organizations: organizationsData,
                        is_active: IsActive
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/admin/sdks');
                        }
                    })
                    .catch(function (error) {

                    });
            }else{
                axios.post(configData.API_SERVER + 'sdk' ,
                    {
                        name: Name,
                        organizations: organizationsData,
                        is_active: IsActive
                    },
                    {
                        headers: {"Authorization" : `Bearer ${account.token}`}
                    })
                    .then(function (response) {
                        if (response.status == 200 || response.status == 204) {
                            toast.success('Successful Operation !', {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return history.push('/admin/sdks');
                        }
                    })
                    .catch(function (error) {

                    });
            }
        } catch (err) {
        }
    }

    const getClientes = async () => {
        setLoadingData(true);
        try {
            axios.get(configData.API_SERVER + 'sdk/organizations' ,
                { headers: {"Authorization" : `Bearer ${account.token}`}
                })
                .then(function (response) {
                    if (response.status == 200) {
                        var result = response.data;
                        var data = [];
                            result.map((option) => (
                                data.push({
                                    value: option.id,
                                    label: option.name
                                })
                            ));
                        setClients(data);
                        setLoadingData(false);

                    }
                    if (Clients.length)
                        setLoadingData(false);
                })
                .catch(function (error) {
                    setLoadingData(false);
                });
        } catch (err) {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        getById();
        getClientes();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined){
            if(id > 0)
                selector.textContent = "Edit Sdks";
            else
                selector.textContent = "Create Sdks";
        }
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <Form className="create-form" onSubmit={saveData} autoComplete="off">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" type="text" required placeholder='Name' value={Name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Clients</Form.Label>
                                    {/*<Form.Control name="url" type="text" placeholder='Url' value={Url} onChange={(e) => setUrl(e.target.value)} />*/}
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        components={animatedComponent}
                                        onChange={(items) => setSelectedOptions(items)}
                                        options={Clients}
                                        value={selectedOptions}
                                    />
                                </Form.Group>
                            </div>
                            <div className="mb-3 col-md-6 col-sm-12">
                                <Form.Group className="form-group">
                                    <Form.Label>Is Active</Form.Label>
                                    <Form.Check name="Enabled" type="checkbox" checked={IsActive} onChange={(e) => setIsActive(!IsActive)} />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col-12 footer-form">
                                <NavLink className="btn btn-secondary" to="/admin/sdks"><i className="fa fa-fw fa-times"></i> Cancel</NavLink>
                                <Button className="btn btn-primary" type='submit'><i className="fa fa-fw fa-floppy-o"></i> Save</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default SdksForm;
