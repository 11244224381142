import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "./assets/css/font-awesome.min.css";
import configData from './config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "layouts/Admin.js";
import BasicLayout from "layouts/Basic.js";

const layout = window.localStorage.getItem('layout');
const root = ReactDOM.createRoot(document.getElementById("root"));

if(layout == 'Admin'){
    root.render(
        <BrowserRouter>
            <Switch>
                <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                <Redirect from="/" to="admin/resonicadmins" />
            </Switch>
            <ToastContainer theme="colored" autoClose={2000}/>
        </BrowserRouter>
    );
}else{
    root.render(
        <BrowserRouter>
            <Switch>
                <Route path="/manage" render={(props) => <BasicLayout {...props} />} />
                <Redirect from="/" to={configData.defaultPath} />
            </Switch>
            <ToastContainer theme="colored" autoClose={2000}/>
        </BrowserRouter>
    );
}