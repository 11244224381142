import React from 'react';
import DataGridList from "../../../components/CommonList/DataGridList.js";

const Index = () => {

  const columns = [
    { name: 'Name', index: 'name', type: 'text' }
  ];

  const options = {
    crud: true,
    add: {
      route: "/admin/category/form"
    },
    edit: {
      route: "/admin/category/form"
    },
    details: {
      route: "/admin/category/details"
    },
    delete: {
      route: "category/"
    },
    enabledOpt: {
      url_edit: 'category/',
      field: null
    },
    export: {
      route: ""
    },
  }

  const url = "categories"

  const filters = [
    // { name: "Date From", index: 'modified_after', type: 'date'},
    // { name: "Date To", index: 'modified_before', type: 'date'},
    // { name: "Search", index: 'search', type: 'text'},
  ];

  // // Restablece los filtros en el almacenamiento local
  // window.localStorage.removeItem('filters');
  // localStorage.removeItem('currentPage');

  return (
      <DataGridList url={url} columns={columns} options={options} filters={filters}/>
  );
};

export default Index;
