import UserProfile from "views/UserProfile.js";
import DashboardAdmin from "views/admin/DashboardAdmin";
import DashboardAdminForm from "views/admin/DashboardAdminForm";
import DashboardAdminDetails from "views/admin/DashboardAdminDetails";
import MediaProviders from "views/admin/mediaProviders/index.js";
import MediaProvidersForm from "views/admin/mediaProviders/form.js";
import MediaProvidersDetails from "views/admin/mediaProviders/details.js";
import Brands from "views/admin/brands/index.js";
import BrandsForm from "views/admin/brands/form.js";
import BrandsDetails from "views/admin/brands/details.js";
import Sdks from "views/admin/sdks/index.js";
import SdksForm from "views/admin/sdks/form.js";
import SdksDetails from "views/admin/sdks/details.js";
import Categories from "views/admin/categories/index.js";
import CategoryForm from "views/admin/categories/form.js";
import CategoryDetails from "views/admin/categories/details.js";

const account = JSON.parse(window.localStorage.getItem('userLoggin'));

const dashboardAdminRoutes = [
    {
        path: "/resonicadmins",
        name: "Resonic Admins",
        icon: "nc-icon fa fa-fw fa-users",
        component: DashboardAdmin,
        layout: "/admin",
        // redirect: true
    },
    {
        path: "/mediaproviders",
        name:  "Media Providers",
        icon: "nc-icon nc-paper-2",
        component: MediaProviders,
        layout: "/admin"
    },
    {
        path: "/brands",
        name: "Brands",
        icon: "nc-icon nc-bullet-list-67",
        component: Brands,
        layout: "/admin",
        // redirect: true
    },
    {
        path: "/sdks",
        name: "SDK",
        icon: "nc-icon fa fa-fw fa-th-large",
        component: Sdks,
        layout: "/admin"
    },
    {
        path: "/categories",
        name: "Content Categories",
        icon: "nc-icon fa fa-fw fa-list-ul",
        component: Categories,
        layout: "/admin"
    },
    {
        path: "/user",
        name: "User Profile",
        icon: "nc-icon nc-circle-09",
        component: UserProfile,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/resonicadmin/form",
        name: null,
        component: DashboardAdminForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/resonicadmin/details",
        name: null,
        component: DashboardAdminDetails,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/mediaprovider/form",
        name: null,
        component: MediaProvidersForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/mediaprovider/details",
        name: null,
        component: MediaProvidersDetails,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/brand/form",
        name: null,
        component: BrandsForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/brand/details",
        name: null,
        component: BrandsDetails,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/sdk/form",
        name: null,
        component: SdksForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/sdk/details",
        name: null,
        component: SdksDetails,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/category/form",
        name: null,
        component: CategoryForm,
        layout: "/admin",
        redirect: true
    },
    {
        path: "/category/details",
        name: null,
        component: CategoryDetails,
        layout: "/admin",
        redirect: true
    }
];

export default dashboardAdminRoutes;
