import React, { useEffect, useState } from 'react';
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import ApexCharts from 'apexcharts';
import axios from "axios";
import configData from "../config";

const account = JSON.parse(window.localStorage.getItem('userLoggin'));

// const chartOptions = {
//   chart: {
//     height: 350,
//     type: 'donut',
//   },
//   series: [],
//   labels: [],
//   legend: {
//     position: 'bottom',
//   },
//   responsive: [{
//     breakpoint: 480,
//     options: {
//       chart: {
//         width: 200
//       },
//       legend: {
//         position: 'bottom'
//       }
//     }
//   }]
// };

function Dashboard() {
  const [loadingData, setLoadingData] = useState(false);
  const [TopCampaignsChart, setTopCampaignsChart] = useState({});
  const [ActiveTokens, setActiveTokens] = useState(0);
  const [ActiveCampaigns, setActiveCampaigns] = useState(0);
  const [Impressions, setImpressions] = useState(0);
  const [Engagements, setEngagements] = useState(0);

  const getDataDashboard = async () => {
    setLoadingData(true);
    try {
      axios.get(configData.API_SERVER + 'dashboard' ,
          { headers: {"Authorization" : `Bearer ${account.token}`}
          })
          .then(function (response) {
            if (response.status == 200) {
              var result = response.data;

              const chartImpressions = new ApexCharts(document.getElementById("chartImpressions"), result.impressions_chart);
              chartImpressions.render();

              const chartEngagements = new ApexCharts(document.getElementById("chartEngagements"), result.engagements_chart);
              chartEngagements.render();

              setActiveTokens(result.active_tokens);
              setActiveCampaigns(result.active_campaigns);
              setImpressions(result.impressions);
              setEngagements(result.engagements);

            }
              setLoadingData(false);
          })
          .catch(function (error) {
            setLoadingData(false);
          });
    } catch (err) {
      setLoadingData(false);
    }
  }

  useEffect(() => {
    getDataDashboard();
  }, []);

  return (
    <>
      <div className={loadingData ? "preload" : "d-none"}>
        <div className="preloader-spinner"></div>
      </div>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <Card.Title as="h4">Active Channels</Card.Title>
                <p className="card-category">Last 7 days</p>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">{ActiveTokens}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <Card.Title as="h4">Active Campaigns</Card.Title>
                <p className="card-category">Last 7 days</p>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">{ActiveCampaigns}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <Card.Title as="h4">Impressions</Card.Title>
                <p className="card-category">Last 7 days</p>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">{Impressions}</h1>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Header>
                <Card.Title as="h4">Engagements</Card.Title>
                <p className="card-category">Last 7 days</p>
              </Card.Header>
              <Card.Body>
                <h1 className="mt-0">{Engagements}</h1>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Impressions</Card.Title>
                <p className="card-category">Last 7 days</p>
              </Card.Header>
              <Card.Body>
                <div id="chartImpressions"></div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Engagements</Card.Title>
                <p className="card-category">Last 7 days</p>
              </Card.Header>
              <Card.Body>
                <div id="chartEngagements"></div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="4">
          </Col>
        </Row>
      </Container>
     </>
  );
}

export default Dashboard;
