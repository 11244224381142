import React, { useEffect, useState, SyntheticEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {Form, Button} from 'react-bootstrap';
import configData from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from "moment";

const Details = (props) => {
    const [Name, setName] = useState('');
    const [Reference, setReference] = useState('');
    const [Image, setImage] = useState('');
    const [Thumbnail, setThumbnail] = useState('');
    const [Url, setUrl] = useState('');
    const [Enabled, setEnabled] = useState(true);
    const account = JSON.parse(window.localStorage.getItem('userLoggin'));
    const [id, setId] = useState(props?.location?.aboutProps?.id);
    const [loadingData, setLoadingData] = useState(false);
    const history = useHistory();
    const [PreviewImage, setPreviewImage] = useState();
    const [PreviewThumb, setPreviewThumb] = useState();
    const [Categories, setCategories] = useState('');
    const [ExpirationDate, setExpirationDate] = useState('');
    const [RepetitionTime, setRepetitionTime] = useState('');
    const [ReengagementTime, setReengagementTime] = useState('');

    const getById = async () => {
        if (id > 0) {
            setLoadingData(true);
            try {
                axios
                axios.get(configData.API_SERVER + "content/" + id ,
                    { headers: {
                        "Authorization" : `Bearer ${account.token}`
                    }
                    })
                    .then(function (response) {
                        if (response.status == 200) {
                            var result = response.data;
                            setName(result.name);
                            setReference(result.ref);
                            if (result.image != "" && result.image != undefined && result.image != null && result.image != 'null') {
                                setPreviewImage(configData.BASE_PATH_BACKEND + result.image);
                            }
                            if (result.thumbnail != "" && result.thumbnail != undefined && result.thumbnail != null && result.thumbnail != 'null') {
                                setPreviewThumb(configData.BASE_PATH_BACKEND + result.thumbnail);
                            }
                            setUrl(result.url);
                            setEnabled(result.is_active);

                            var categories = result.categories.map(item => item.name).join(', ');
                            setCategories(categories);


                            setExpirationDate(result.expiration_date ? moment(result.expiration_date).format('YYYY-MM-DD') : '');
                            setRepetitionTime(result.repetition_time);
                            setReengagementTime(result.reengagement_time);
                        }
                        setLoadingData(false);
                    })
                    .catch(function (error) {
                        //Swal.fire({
                        //    title: 'Erreur!',
                        //    text: "Une erreur s'est produite",
                        //    icon: 'error',
                        //    confirmButtonText: 'OK'
                        //});
                        setLoadingData(false);
                    });
            } catch (err) {
                //Swal.fire({
                //    title: 'Erreur!',
                //    text: "Une erreur s'est produite",
                //    icon: 'error',
                //    confirmButtonText: 'OK'
                //});
                setLoadingData(false);
            }
        }else{
            return history.push('/manage/contents');
        }
    }

    useEffect(() => {
        getById();
        var selector = document.getElementById('title-section-id');
        if(selector != undefined)
            selector.textContent = "Campaign details";
    }, []);

    return (
        <>
            <div className={loadingData ? "preload" : "d-none"}><div className="preloader-spinner"></div></div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Name: </label>
                            <p className="card-text">{Name}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Url: </label>
                            <p className="card-text">{Url}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Reference: </label>
                            <p className="card-text">{Reference}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Is Active: </label>
                            <p className="card-text">{Enabled ? "True" : "False"}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Image: </label>
                            <p className="card-text"><img className="max-width-300" src={PreviewImage} alt=""/></p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Thumbnail: </label>
                            <p className="card-text"><img className="max-width-300" src={PreviewThumb} alt=""/></p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Expiration Date: </label>
                            <p className="card-text">{ExpirationDate}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Categories: </label>
                            <p className="card-text">{Categories}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Frequency capping: </label>
                            <p className="card-text">{RepetitionTime}</p>
                        </div>
                        <div className="col-sm-12 col-md-6 mb-25">
                            <label>Re-engagement frequency: </label>
                            <p className="card-text">{ReengagementTime}</p>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <NavLink className="btn btn-secondary" to="/manage/contents"><i className="fa fa-chevron-left"></i> Back</NavLink>
                </div>
            </div>
        </>
    );
};

export default Details;
